import { Colors } from "srs.sharedcomponents/lib/esm/styles/config";

const nissanThemeColor = "#4c4c4c";
export const customTheme = {
  colors: {
    primary: nissanThemeColor,
    navTextButtonColor: Colors.white,
    formLabel: Colors.black,
    menuButton: Colors.white,
    menuButtonMobile: Colors.black,
    topNavigation: Colors.white,
  },
};
